import React, { useEffect, useState } from "react";

import {
    Divider,
    Button,
    Row,
    Col,
    Drawer,
    Tag,
    Space,
    Radio,
    Descriptions,
    Spin,
    DatePicker,
    message,
    Popconfirm,
    Alert,
    Typography,
    Select,
    Form,
    Input
} from "antd";

import {
    CloseSquareOutlined,
    SaveOutlined,
    CloseCircleOutlined,
    RollbackOutlined,
    DownSquareFilled,
    MailOutlined
} from "@ant-design/icons";

import { JourneyPicker } from "../common/JourneyPicker";

// import { UpdatePassengerList } from "./passengers/UpdatePassengerList";

import * as Constants from "../../utils/Constants";
import * as ToryService from "../../services/ToryService";
import TimeSelector from "./ferry/TimeSelector";
import moment from "moment";
import { PassengerListBuilder } from "./passengers/PassengerListBuilder";
import { EditNamePanel } from "./Edit/EditNamePanel";

export default function EditPanel(props) {
    //
    const { singleTicket, width, onClose, visible, archiveTicket } = props;

    const [ticket, setTicket] = useState(singleTicket);
    const [outTimes, setOutTimes] = useState(null);
    const [returnTimes, setReturnTimes] = useState(null);
    const [update, setUpdate] = useState(0);

    useEffect(() => {
        setupDates(singleTicket.ticket.outDate, singleTicket.ticket.returnDate);
    }, [singleTicket]);

    async function setupDates(outDate, returnDate) {
        if (outDate !== null) {
            let outResponse = await ToryService.getDateTimes(ticket.ticket.outJourney, Constants.getEpochDate(outDate));
            setOutTimes(Constants.getOneDayTimesArray(outResponse));
        }
        if (returnDate !== null) {
            let returnResponse = await ToryService.getDateTimes(
                ticket.ticket.outJourney === "dm" ? "dt" : "dm",
                Constants.getEpochDate(returnDate)
            );
            setReturnTimes(Constants.getOneDayTimesArray(returnResponse));
        }
    }

    async function updateMeta(property, value) {
        let updated = singleTicket;

        let newmeta = singleTicket.meta ? { ...singleTicket.meta } : {};
        // console.log(updated);
        newmeta[property] = value;
        updated.meta = newmeta;
        // console.log(updated.meta);
        setTicket(updated);
        setUpdate(update + 1);
    }

    function setOutJourney(journey) {
        let newTicket = ticket;
        newTicket.ticket.outJourney = journey;
        setTicket(newTicket);
        onOutDateChange({ _d: newTicket.ticket.outDate });
        onReturnDateChange({ _d: newTicket.ticket.returnDate });
        setUpdate(update + 1);
    }

    async function onOutDateChange(date) {
        if (date !== null) {
            let newTicket = ticket;
            newTicket.ticket.outDate = Constants.getEpochDate(date._d);
            let response = await ToryService.getDateTimes(ticket.ticket.outJourney, Constants.getEpochDate(date._d));
            // RESET TICKET TIMES
            newTicket.ticket.outTime = null;
            newTicket.ticket.outReroute = false;
            setTicket(newTicket);
            setOutTimes(Constants.getOneDayTimesArray(response));
            setUpdate(update + 1);
        }
    }

    async function onReturnDateChange(date) {
        if (date !== null) {
            let newTicket = ticket;
            newTicket.ticket.returnDate = Constants.getEpochDate(date._d);
            // setState({ticket: newTicket});
            let response = await ToryService.getDateTimes(ticket.ticket.outJourney === "dm" ? "dt" : "dm", Constants.getEpochDate(date._d));
            // updateTicket("outTime", response.times);
            newTicket.ticket.returnTime = response.times;
            newTicket.ticket.returnReroute = false;
            setTicket(newTicket);
            setUpdate(update + 1);
            setReturnTimes(Constants.getOneDayTimesArray(response));
        }
    }

    function updateTicketReturnType(returnType) {
        // console.log(returnType);
        let updatedTicket = singleTicket;
        if (returnType === 2) {
            updatedTicket.ticket.openReturn = true;
            updatedTicket.ticket.returnTicket = true;
            updatedTicket.ticket.returnReroute = false;
            updatedTicket.ticket.returnTime = 0;
            // updateTicketDetails("openReturn", true);
            // updateTicketDetails("returnTicket", true);
            // updateTicketDetails("returnReroute", false);
            onReturnDateChange({ _d: new Date(new Date().getTime()) });
            // updateTicket("returnTime", 0);
        }
        if (returnType === 1) {
            updatedTicket.ticket.openReturn = false;
            updatedTicket.ticket.returnTicket = true;
            updatedTicket.ticket.returnReroute = false;
            onReturnDateChange({ _d: new Date(new Date().getTime()) });
            updatedTicket.ticket.returnTime = 0;
            // updateTicketDetails("returnTicket", true);
            // updateTicketDetails("openReturn", false);
            // onReturnDateChange({ _d: new Date(starterDate.getTime()) });
            // updateTicket("returnTime", 0);
        }
        if (returnType === 0) {
            updatedTicket.ticket.openReturn = false;
            updatedTicket.ticket.returnTicket = false;
        }
        setTicket(updatedTicket);
        setUpdate(update + 1);
    }

    function setOutTime(timeString, reroute) {
        // console.log(timeString)
        let newTicket = ticket;
        newTicket.ticket.outTime = timeString;
        newTicket.ticket.outReroute = reroute;
        setTicket(newTicket);
        setUpdate(update + 1);
    }
    function setReturnTime(timeString, reroute) {
        let newTicket = ticket;
        newTicket.ticket.returnTime = timeString;
        newTicket.ticket.returnReroute = reroute;
        setTicket(newTicket);
        setUpdate(update + 1);
    }

    async function deleteTicket() {
        // CALL SERVER
        let response = await ToryService.deleteTicket(ticket._id);
        if (response.status === 200) {
            message.success("Ticket Deleted");
            onClose();
        } else {
            message.error("Can't delete ticket");
        }
    }

    async function sendReceipt(id) {
        // console.log(`id of receipt ${id}`);
        let response = await ToryService.sendReceipt(id);
        if (response.status === 200) {
            message.success("Ticket Receipt Sent");
            // this.props.onClose();
            // setShowEditDrawer(false);
            // loadTickets();
        } else {
            message.error("Can't send receipt for ticket");
        }
    }

    async function sendUpdateReceipt(id) {
        // console.log(`id of receipt ${id}`);
        let response = await ToryService.sendUpdateReceipt(id);
        if (response.status === 200) {
            message.success("Ticket Update Email Sent");
            // this.props.onClose();
            // setShowEditDrawer(false);
            // loadTickets();
        } else {
            message.error("Can't send email for ticket");
        }
    }

    async function refundTicket() {
        // CALL SERVER
        let response = await ToryService.refundTicket(ticket._id);
        if (response.status === 200) {
            message.success("Ticket Refunded");
            onClose();
        } else {
            message.error("Can't refund ticket");
        }
    }

    async function updateStatus(status) {
        let newStatus = Constants.getServerStatus(status);
        let newTicket = ticket;
        newTicket.status = newStatus;
        setTicket(newTicket);
        setUpdate(update + 1);
        updateTicketStatus();
    }

    async function updateTicketStatus() {
        // console.log(readyTicket);
        let response = await ToryService.updateTicketStatus(ticket._id, ticket.status);
        // console.log(response);
        if (response.status === 200) {
            message.success("Ticket Updated");
        } else {
            message.error("Ticket cannot be updated on the server");
        }
    }

    // updatePassengerList = (type, value) => {
    async function saveTicketToServer() {
        // console.log(ticket);
        let readyTicket = Constants.getServerTicket(ticket);
        // console.log(readyTicket);
        // let ticket = ticket
        // VALIDATE TICKET FIRST
        if (validateTicket(readyTicket)) {
            let response = await ToryService.updateTicket(readyTicket);
            // console.log(response);
            if (response.status === 200) {
                message.success("Ticket Updated");
            } else {
                message.error("Ticket cannot be updated on the server");
            }
        } else {
            message.error("Ticket cannot be updated on the server");
        }
    }

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    //
    function updatePassengers(passenger) {
        let currentPassengers = singleTicket.passengers;
        let matchingCodeIndex = currentPassengers.map((cp) => cp.code).indexOf(passenger.code);
        if (matchingCodeIndex > -1) {
            currentPassengers[matchingCodeIndex].quantity += 1;
        } else {
            currentPassengers.push(passenger);
        }
        // updateTicket("passengers", currentPassengers);
        let newTicket = singleTicket;
        newTicket.passengers = currentPassengers;
        setTicket(newTicket);
        setUpdate(update + 1);
    }

    //
    function updateCustomPrice(passenger) {
        let currentPassengers = singleTicket.passengers;
        currentPassengers.push(passenger);
        // updateTicket("passengers", currentPassengers);
        let newTicket = singleTicket;
        newTicket.passengers = currentPassengers;
        setTicket(newTicket);
        setUpdate(update + 1);
    }
    //
    function removePassenger(code) {
        let currentPassengers = singleTicket.passengers;
        let matchingCodeIndex = currentPassengers.map((cp) => cp.code).indexOf(code);
        if (matchingCodeIndex > -1) {
            if (currentPassengers[matchingCodeIndex].quantity > 1) {
                currentPassengers[matchingCodeIndex].quantity -= 1;
            } else {
                currentPassengers.splice(matchingCodeIndex, 1);
            }
        }
        // updateTicket("passengers", currentPassengers);
        let newTicket = singleTicket;
        newTicket.passengers = currentPassengers;
        setTicket(newTicket);
        setUpdate(update + 1);
    }
    //
    const [editName, setEditName] = useState(false);

    function updateName(name, email) {
        props.singleTicket.name = name;
        props.singleTicket.email = email;
    }

    return (
        <Drawer
            title="Edit Ticket"
            placement="right"
            closable={true}
            closeIcon={<CloseSquareOutlined />}
            width={width}
            onClose={onClose}
            open={visible}
        >
            {singleTicket ? (
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Descriptions title="Ticket" layout="vertical" size="small" bordered>
                        {/* ROW */}
                        <Descriptions.Item label="Customer" span={2}>
                            <Space direction="vertical" style={{ width: "100%" }} size={"small"}>
                                {singleTicket.name}
                                <a href={"mailto:" + props.singleTicket.email + ""}>{singleTicket.email}</a>
                                <Button size="small" onClick={() => setEditName(true)}>
                                    Edit
                                </Button>
                            </Space>
                            <EditNamePanel
                                open={editName}
                                ticket={props.singleTicket}
                                close={() => setEditName(false)}
                                onUpdate={(n, e) => updateName(n, e)}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <Space direction="vertical" style={{ width: "100%" }} size="small">
                                <Tag color={Constants.getPaidStatusColour(singleTicket.status)}>
                                    {Constants.getPaidStatus(singleTicket.status)}
                                </Tag>
                                <Select
                                    style={{ width: "100%" }}
                                    onChange={updateStatus}
                                    value={Constants.getPaidStatus(singleTicket.status)}
                                    // value={singleTicket.status}
                                >
                                    <Select.Option value={"Paid"}>Paid</Select.Option>
                                    <Select.Option value={"Reserved"}>Reserved</Select.Option>
                                    <Select.Option value={"Inactive"}>Inactive</Select.Option>
                                    <Select.Option value={"Refund Requested"}>Refund Requested</Select.Option>
                                    <Select.Option value={"Refunded"}>Refunded</Select.Option>
                                    <Select.Option value={"Limbo"}>Limbo</Select.Option>
                                    <Select.Option value={"Requires Action!"}>Requires Action!</Select.Option>
                                </Select>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Return Ticket" span={3}>
                            {/* <Radio.Group value={ticket.ticket.returnTicket} onChange={(e) => setReturnTicket(e.target.value)} buttonStyle="solid">
                                <Radio.Button value={true}>Return</Radio.Button>
                                <Radio.Button value={false}>One Way</Radio.Button>
                            </Radio.Group> */}
                            <Space style={{ width: "100%" }} direction="vertical">
                                <Radio.Group
                                    value={singleTicket.ticket.openReturn ? 2 : singleTicket.ticket.returnTicket ? 1 : 0}
                                    onChange={(v) => updateTicketReturnType(v.target.value)}
                                    buttonStyle="solid"
                                >
                                    <Radio.Button value={0}>One Way</Radio.Button>
                                    <Radio.Button value={1}>Return</Radio.Button>
                                    <Radio.Button value={2}>Open Return</Radio.Button>
                                </Radio.Group>

                                {singleTicket.ticket.openReturn === true ? (
                                    <Alert message={`Remind customer to hold onto ticket, for scanning later`} type="warning" />
                                ) : (
                                    <></>
                                )}
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Meta" span={3}>
                            <Form
                                labelCol={{
                                    span: 8
                                }}
                                wrapperCol={{
                                    span: 16
                                }}
                            >
                                {Constants.maxDeckSpacesAvailable > 0 ? (
                                    <Form.Item label="Car Details">
                                        <Input
                                            placeholder="Make and Models"
                                            onChange={(v) => updateMeta("cardetails", v.target.value)}
                                            value={ticket.meta.cardetails}
                                        />
                                    </Form.Item>
                                ) : (
                                    <></>
                                )}
                                <Form.Item label="Special Reqs">
                                    <Input
                                        placeholder="Wheelchair Access..."
                                        onChange={(v) => updateMeta("specialreq", v.target.value)}
                                        value={ticket.meta ? ticket.meta.specialreq ?? "" : ""}
                                    />
                                </Form.Item>
                                <Form.Item label="Number">
                                    <Input
                                        placeholder="+353..."
                                        onChange={(v) => updateMeta("number", v.target.value)}
                                        value={ticket.meta ? ticket.meta.number ?? "" : ""}
                                    />
                                </Form.Item>
                                <Form.Item label="Smart Pass">
                                    <Input
                                        placeholder="Smart Pass ID"
                                        onChange={(v) => updateMeta("smartpass", v.target.value)}
                                        value={ticket.meta ? ticket.meta.smartpass ?? "" : ""}
                                    />
                                </Form.Item>
                            </Form>
                        </Descriptions.Item>
                        <Descriptions.Item label="Journey" span={3}>
                            <Space direction="vertical">
                                <JourneyPicker journey={ticket.ticket.outJourney} onJourneyChange={(e) => setOutJourney(e.target.value)} />
                                <Typography.Text>
                                    Will Return:{" "}
                                    {ticket.ticket.outJourney === "dm"
                                        ? Constants.getJourneyString("dt")
                                        : Constants.getJourneyString("dm")}
                                </Typography.Text>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Dates" span={3}>
                            <Row type="flex">
                                <Col xs={24} md={12}>
                                    <Space style={{ width: "100%", padding: "15px 0" }} direction="vertical">
                                        <Typography.Title level={5}>Out Date</Typography.Title>
                                        <DatePicker
                                            value={moment(new Date(Constants.getNumericalDateString(ticket.ticket.outDate)))}
                                            onChange={onOutDateChange}
                                        />
                                        <Typography.Title level={5}>Out Time</Typography.Title>
                                        <TimeSelector time={singleTicket.ticket.outTime} timeArray={outTimes} setTime={setOutTime} />
                                        {ticket.ticket.outReroute ? <Tag color="orange">* REROUTED FERRY</Tag> : <></>}
                                    </Space>
                                </Col>
                                {ticket.ticket.returnTicket && !ticket.ticket.openReturn ? (
                                    <Col xs={24} md={12}>
                                        <Space style={{ width: "100%", padding: "15px 0" }} direction="vertical">
                                            <Typography.Title level={5}>Return Date</Typography.Title>
                                            <DatePicker
                                                value={moment(new Date(Constants.getNumericalDateString(ticket.ticket.returnDate)))}
                                                onChange={onReturnDateChange}
                                            />
                                            <Typography.Title level={5}>Return Time</Typography.Title>
                                            <TimeSelector
                                                time={singleTicket.ticket.returnTime}
                                                timeArray={returnTimes}
                                                setTime={setReturnTime}
                                            />
                                            {ticket.ticket.returnReroute ? <Tag color="orange">* REROUTED FERRY</Tag> : <></>}
                                        </Space>
                                    </Col>
                                ) : (
                                    <></>
                                )}
                            </Row>
                        </Descriptions.Item>
                        <Descriptions.Item label="Passengers" span={3}>
                            {/* <UpdatePassengerList updatePassengerList={(i, v) => updatePassengerList(i, v)} newTicket={ticket} /> */}

                            <PassengerListBuilder
                                updatePassengers={(t) => updatePassengers(t)}
                                updateCustomPrice={(c) => updateCustomPrice(c)}
                                removePassenger={removePassenger}
                                newTicket={singleTicket}
                            />
                        </Descriptions.Item>
                    </Descriptions>

                    <Row type="flex" style={{ width: "100%" }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            {validateTicket(ticket) ? (
                                <Divider orientation="right">
                                    <Button onClick={saveTicketToServer} icon={<SaveOutlined />} type="primary">
                                        Update Ticket
                                    </Button>
                                </Divider>
                            ) : (
                                <></>
                            )}

                            <Typography.Title level={4}>Actions</Typography.Title>

                            <Space style={{ width: "100%" }} size={"large"} direction="vertical">
                                {/* <Space direction="horizontal"> */}
                                <Popconfirm
                                    title="Are you send a receipt to this ticket?"
                                    okText="Yes"
                                    onConfirm={() => sendReceipt(ticket._id)}
                                    onCancel={Constants.cancel}
                                    cancelText="No"
                                >
                                    <Button type="ghost" icon={<MailOutlined />}>
                                        Send Receipt
                                    </Button>
                                    {/* <Button danger icon={<CloseCircleOutlined twoToneColor="#ff4d4f" />}> Delete Ticket</Button> */}
                                </Popconfirm>
                                <Popconfirm
                                    title="Are you send an updated receipt to this ticket?"
                                    okText="Yes"
                                    onConfirm={() => sendUpdateReceipt(ticket._id)}
                                    onCancel={Constants.cancel}
                                    cancelText="No"
                                >
                                    <Button type="ghost" icon={<MailOutlined />}>
                                        Send Update Confirmation
                                    </Button>
                                </Popconfirm>
                                {/* </Space> */}

                                <Popconfirm
                                    title="Are you sure archive this ticket?"
                                    okText="Yes"
                                    onConfirm={() => archiveTicket(ticket._id)}
                                    onCancel={Constants.cancel}
                                    cancelText="No"
                                >
                                    <Button type="primary" icon={<DownSquareFilled />}>
                                        Archive Ticket
                                    </Button>
                                    {/* <Button danger icon={<CloseCircleOutlined twoToneColor="#ff4d4f" />}> Delete Ticket</Button> */}
                                </Popconfirm>

                                {singleTicket.payment.stripePayment ? (
                                    <Popconfirm
                                        title="Are you sure refund this ticket?"
                                        okText="Yes"
                                        onConfirm={() => refundTicket(ticket._id)}
                                        onCancel={Constants.cancel}
                                        cancelText="No"
                                    >
                                        <Button type="danger" icon={<RollbackOutlined />}>
                                            Refund and Cancel
                                        </Button>
                                    </Popconfirm>
                                ) : (
                                    <></>
                                )}
                                {/* DELETE */}
                                <Popconfirm
                                    title="Are you sure delete this ticket?"
                                    okText="Yes"
                                    onConfirm={() => deleteTicket(ticket._id)}
                                    onCancel={Constants.cancel}
                                    cancelText="No"
                                >
                                    <Button type="outline" danger icon={<CloseCircleOutlined twoToneColor="#ff4d4f" />}>
                                        Delete Ticket
                                    </Button>
                                </Popconfirm>

                                <Button target="_blank" href={`${Constants.websiteURL}/tickets/confirmation?t=${ticket._id}`}>
                                    View On Web
                                </Button>
                            </Space>

                            <br />
                        </Space>
                    </Row>

                    <Descriptions title="Server Side" layout="vertical" bordered>
                        {/* ROW */}
                        <Descriptions.Item label="Date Bought">{Constants.getShortDateString(singleTicket.datecreated)}</Descriptions.Item>
                        <Descriptions.Item label="Stripe Reference">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={"https://dashboard.stripe.com/payments/" + props.singleTicket.payment.stripePayment}
                            >
                                {singleTicket.payment.stripePayment}
                            </a>
                        </Descriptions.Item>
                        <Descriptions.Item label="Method">{Constants.getMethodIcon(singleTicket.method)}</Descriptions.Item>
                        {/* ROW */}
                        <Descriptions.Item label="DB Info" span={3}>
                            Ticket ID: {singleTicket._id}
                            <br />
                            User ID: {singleTicket.userid}
                            {/* Date Created: {Constants.getDateString(singleTicket.datecreated)} */}
                            <br />
                        </Descriptions.Item>
                    </Descriptions>
                </Space>
            ) : (
                <Col xs={18} md={8} style={{ textAlign: "center" }}>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <Spin />
                    </div>
                </Col>
            )}
        </Drawer>
    );
}

// export default EditPanel;

const validateTicket = function (ticket) {
    // let ticket = ticket
    let error = null;
    // JOURNEY
    if (ticket.ticket.returnTicket !== true || ticket.ticket.returnTicket !== false) {
        error = "Ticket Type is wrong";
    }
    // ORIGIN
    if (ticket.ticket.outJourney !== "dm" || ticket.ticket.outJourney !== "dt") {
        error = "Journeys are wrong";
    }
    // TIMES
    if (Array.isArray(ticket.ticket.outTime) || Array.isArray(ticket.ticket.returnTime)) {
        error = "Times aren't selected";
    }
    if (error !== null) {
        return error;
    } else {
        return true;
    }
    // return true;
};
