import React from "react";

import { message, Tooltip, Typography } from "antd";

import {
    GoogleCircleFilled,
    MailOutlined,
    AppleFilled,
    FacebookFilled,
    TwitterCircleFilled,
    MobileOutlined,
    DesktopOutlined,
    CommentOutlined,
    ShoppingCartOutlined,
    EuroCircleOutlined,
    CreditCardOutlined,
    CrownOutlined
} from "@ant-design/icons";

import { format, isAfter, subDays } from "date-fns";

const pointToProduction = true;
export const debug = pointToProduction ? false : process.env.NODE_ENV === "development" ? true : false;

export const maxDeckSpacesAvailable = 0;

export function getApiUrl() {
    if (debug) {
        return testApiURL;
    } else {
        return apiURL;
    }
}
export const ampm = true;

export const apiURL = "https://api.toryferry.com";
export const testApiURL = "http://localhost:2800";
// export const testApiURL = "https://beta.api.toryferry.com";
//
export const websiteURL = process.env.NODE_ENV === "development" ? "http://localhost:3600" : "https://toryferry.com";

// MASTER
export const masterTimes = "/timetables";
export const adminMasterTimes = "/timetables?ref=master";
export const masterTimesAdmin = "/v5/app/timetables/admin";
export const addMasterTimes = "/admin/timetables/master/add";
export const removeMasterTimes = "/admin/timetables/ferry/remove/";
export const oneDayTimes = "/admin/timetables/date/ferries/";
// V2 TIMETABLES
export const v2AdminTimetables = "/v2/timetables";

// DASHBOARD
export const adminDashboard = "/admin/timetables/today";
// CANCEL
export const adminCancel = "/admin/timetables/cancel";
export const adminCancelArchive = "/admin/timetables/cancel/archive";
export const oneDayCancelTimes = "/admin/timetables/cancel/dateTimes";
export const adminCancelAdd = "/admin/timetables/cancel/add";
export const adminCancelRemove = "/admin/timetables/cancel/remove/";
export const adminCancelAddConfirm = "/admin/timetables/cancel/confirm";
export const adminCancelMove = "/admin/timetables/cancel/move";

// LOCK
export const adminLock = "/admin/timetables/lock";
export const adminLockArchive = "/admin/timetables/lock/archive";
export const oneDayLockTimes = "/admin/timetables/lock/dateTimes";
export const adminLockAdd = "/admin/timetables/lock/add";
export const adminLockRemove = "/admin/timetables/lock/remove/";
export const adminLockAddConfirm = "/admin/timetables/lock/confirm";
// EXTRA
export const adminExtra = "/admin/timetables/extra";
export const adminExtraArchive = "/admin/timetables/extra/archive";
export const adminExtraAdd = "/admin/timetables/extra/add";
export const adminExtraRemove = "/admin/timetables/extra/remove/";
// REROUTE
export const adminReroute = "/admin/timetables/reroute";
export const adminRerouteArchive = "/admin/timetables/reroute/archive";
export const oneDayRerouteTimes = "/admin/timetables/reroute/dateTimes";
export const adminRerouteAdd = "/admin/timetables/reroute/add";
export const adminRerouteRemove = "/admin/timetables/reroute/remove/";
export const adminRerouteAddConfirm = "/admin/timetables/reroute/confirm";
// MESSAGES
export const adminMessages = "/admin/messages";
export const adminMessagesAdd = "/admin/messages/add";
export const adminMessagesRemove = "/admin/messages/remove/";
export const adminMessagesArchive = "/admin/messages/archive";
// NOTIFICATIONS
export const adminNotificationsArchive = "/admin/notifications/archive";
export const sendNotification = "/admin/notifications/send";
// TICKETS// TICKETS
export const adminAdvancedTickets = "/admin/tickets/advanced";
// export const adminOneTicket = "/admin/tickets/id/:id";
export const adminLimboTickets = "/admin/tickets/limbo";
export const adminTicketsArchive = "/admin/v1/tickets/get/archive";
export const adminSearchTickets = "/admin/tickets/search";

// RECURRING TICKETS
export const addRecurringV2Get = "/v2/admin/recurring";
export const addRecurringV2Add = "/v2/admin/recurring/add";
export const addRecurringV2Update = "/v2/admin/recurring/update";
// OLD RECURRING TICKETS
export const recurringTicket = "/admin/recurring";
export const addRecurringTicket = "/admin/recurring/new";
export const updateRecurringTicket = "/admin/recurring/update";
export const updateStatusRecurringTicket = "/admin/recurring/update/status";
export const updateMetaRecurringTicket = "/admin/recurring/update/meta";
export const deleteRecurringTicket = "/admin/recurring/delete";
export const searchRecurringTicket = "/admin/recurring/search";
export const getPreviewRecurringTicket = "/admin/recurring/preview";

export const adminTickets = "/admin/v1/tickets/get";
export const addNewTicket = "/admin/v1/tickets/new";

//
export const addNewTicketv2 = "/v2/admin/tickets/add";
export const updateTicketv2 = "/v2/admin/tickets/update";
export const getTicketDateTime = "/v2/admin/tickets/datetime";
export const updateTicketv2NameEmail = "/v2/admin/tickets/update/nameemail";
export const updateCarStatus = "/v2/admin/tickets/carstatus";

export const updateTicket = "/admin/v1/tickets/update";
export const updateTicketStatus = "/admin/v1/tickets/update/status";
export const archiveTicket = "/admin/v1/tickets/archive";
export const deleteTicket = "/admin/v1/tickets/delete";
export const refundTicket = "/admin/tickets/refund";
export const cleanUpTickets = "/admin/v1/tickets/clean";
export const getTicketDate = "/admin/tickets/date";
// BLOCK
export const getTicketBlock = "/admin/block";
export const addTicketBlock = "/admin/block/add";
export const deleteTicketBlock = "/admin/block/delete";
// PRICES
export const prices = "/prices";
// export const adminPrices = "/prices/admin";
export const adminPricesAdd = "/v2/admin/prices/add";
export const adminPricesUpdate = "/v2/admin/prices/update";
export const adminPricesDelete = "/prices/admin/delete";
export const adminPricesRankUpdate = "/prices/admin/rank/update";
// RECEIPT
export const ticketReceipt = "/admin/tickets/receipt";
export const ticketReceiptUpdate = "/admin/tickets/receipt/update";
// AUTH
export const createAccount = "/app/account/create";
export const getAccount = "/app/account/get";
// ACCOUNT MANAGEMENT
export const getAccounts = "/admin/accounts/get";
export const updateAccounts = "/admin/accounts/update";
export const deleteAccounts = "/admin/accounts/delete";
export const searchAccounts = "/admin/accounts/search";

// DEFINITIONS
export const definitions = "/admin/definitions";
export const addDefinitions = "/admin/definitions/add";
export const removeDefinitions = "/admin/definitions/remove";
export const prioritiseDefinitions = "/admin/definitions/priority";
// PHONE
export const settingsPhone = "/settings/phone";
// DASHBOARD
export const adminDashboardData = "/admin/dashboard?allowMinus=1";
// DASHBOARD
export const v2AdminTimeKeys = "/v2/admin/dashboard/timekeys";
export const v2AdminTickets = "/v2/admin/dashboard/tickets";
// V2
export const v2AdminTicket = "/v2/admin/tickets";
export const v2AdminTicketFilter = "/v2/admin/tickets/filter";
export const v2messages = "/v2/admin/messages";
// NOTES
export const adminNotes = "/v2/admin/notes";
export const adminNotesDates = "/v2/admin/notes/dates";

// GOLDEN TICKETS
export const goldenTickets = "/v2/goldentickets";
export const goldenRedeemedTickets = "/v2/goldentickets/redeemed";

// 

export function getNotificationEndpoint(type) {
    if (debug === true) {
        return "/firebase/notifications/test";
    } else if (type === "news") {
        return "/firebase/notifications/news";
    } else if (type === "change") {
        return "/firebase/notifications/change";
    }
}

export function getTheme() {
    // return true
    let res = localStorage.getItem("theme") === "dark" ? true : false;
    // console.log(res);
    return res;
}

export function cancel() {
    message.info("Cancelled");
}

export function getEpochDate(dateTime) {
    // console.log(dateTime);
    let formattedDate = new Date(dateTime);
    formattedDate.setHours(12, 0, 0, 0);
    return formattedDate.getTime();
}

export function getNumberFromTimeString(timeString) {
    // console.log(timeString);
    const newString = timeString.substring(0, 2) + timeString.substring(timeString.length - 2, timeString.length);
    // console.log(newString);
    return parseInt(newString);
}

export function getFirstName(string) {
    const firstName = string.substr(0, string.indexOf(" "));
    return firstName;
}

export function getSocialIcon(provider) {
    switch (provider) {
        case "google":
            return (
                <Tooltip title="Logged in with Google">
                    <GoogleCircleFilled style={{ color: "#cf1322", fontSize: "1.5em" }} />
                </Tooltip>
            );
        case "facebook":
            return (
                <Tooltip title="Logged in with Facebook">
                    <FacebookFilled style={{ color: "#0050b3", fontSize: "1.5em" }} />
                </Tooltip>
            );
        case "apple":
            return (
                <Tooltip title="Logged in with Apple">
                    <AppleFilled style={{ color: "#1f1f1f", fontSize: "1.5em" }} />
                </Tooltip>
            );
        case "twitter":
            return (
                <Tooltip title="Logged in with Twitter">
                    <TwitterCircleFilled style={{ color: "#69c0ff", fontSize: "1.5em" }} />
                </Tooltip>
            );
        default:
            return (
                <Tooltip title="Logged in with Email">
                    <MailOutlined style={{ color: "#141414", fontSize: "1.5em" }} />
                </Tooltip>
            );
    }
}

export function getMethodIcon(method) {
    // APP WEB
    switch (method) {
        case "app":
            return (
                <Tooltip title="Mobile App">
                    <MobileOutlined />
                </Tooltip>
            );
        case "web":
            return (
                <Tooltip title="Website">
                    <DesktopOutlined />
                </Tooltip>
            );
        case "guest":
            return (
                <Tooltip title="Guest Checkout">
                    <ShoppingCartOutlined />
                </Tooltip>
            );
        case "rx":
            return (
                <Tooltip title="RX Ticket">
                    {/* <OldTicketIcon /> */}
                    <Typography.Text>RX Ticket</Typography.Text>
                </Tooltip>
            );
        case "admin":
            return (
                <Tooltip title="Admin Added this Ticket">
                    <CrownOutlined />
                </Tooltip>
            );
        case "cash":
            return (
                <Tooltip title="Cash Ticket">
                    <EuroCircleOutlined />
                </Tooltip>
            );
        case "card":
            return (
                <Tooltip title="Card Payment">
                    <CreditCardOutlined />
                </Tooltip>
            );
        default:
            return (
                <Tooltip title="Unknown">
                    <CommentOutlined />
                </Tooltip>
            );
    }
}

export function compileArrayIDsToKeys(array, key) {
    const newArray = [];
    for (let i = 0; i < array.length; i++) {
        const element = array[i];
        // console.log(array[i][key]);
        // console.log(array[i]);
        if (key === null || key === undefined) {
            element.key = array[i].id;
        } else {
            // console.log("key used`");
            element.key = array[i][key];
        }
        newArray.push(element);
    }
    return newArray;
}

export function getJourneyString(journey, reroute) {
    if (reroute === null || reroute === undefined) {
        // NO REROUTES
        return journey === "dm" ? "Departing Magheroarty" : "Departing Tory Island";
    } else {
        // REROUTES
        return journey === "dm" ? "Departing Magheroarty" : "Departing Tory Island";
    }
}
export function getIDString(id) {
    return id.substring(id.length - 6);
}

export function getDateString(epoch) {
    let date = new Date(epoch);
    // let dateString = days[date.getDay()] + ", " + date.getDate() + nth(date.getDate()) + " " + months[date.getMonth()] + " " + date.getFullYear();
    const dateString = format(date, "EEE, do MMM yyyy");
    return dateString;
}

export function getShortDateString(epoch) {
    let date = new Date(epoch);
    // let dateString = shortDays[date.getDay()] + ", " + date.getDate() + "/" + shortMonths[date.getMonth()] + "/" + date.getFullYear().toString().substring(2);
    let dateString = format(date, "dd, MMM yyyy");
    return dateString;
}
export function getNumericalDateString(epoch) {
    let date = new Date(0);
    // console.log(epoch);
    date.setUTCMilliseconds(epoch);
    // console.log(date);
    // let dateString = date.getDate() + "-" + numberMonths[date.getMonth()] + "-" + date.getFullYear().toString();
    let dateString = format(date, "yyyy-MM-dd");
    // console.info(dateString);
    return dateString;
}

export function getDateTimeString(epoch) {
    let date = new Date(epoch);
    let dateTimeString =
        days[date.getDay()] +
        ", " +
        date.getDate() +
        nth(date.getDate()) +
        " " +
        months[date.getMonth()] +
        " " +
        date.getFullYear() +
        " at " +
        date.getHours() +
        ":" +
        lessThanTenAddZero(date.getMinutes());
    return dateTimeString;
}

function nth(d) {
    if (d > 3 && d < 21) return "th"; // thanks kennebec
    switch (d % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}

export function lessThanTenAddZero(number) {
    var stringNum = number.toString();
    if (number < 10) {
        stringNum = "0" + number;
    }
    return stringNum;
}

export function getTimestamp(num) {
    // console.log(num)
    let workingNumber = num;
    let timestamp = 0;
    if (workingNumber === "-") {
        timestamp = workingNumber;
    } else if (num < 1200) {
        if (num < 10) {
            workingNumber = "000" + num;
        } else if (num < 100) {
            workingNumber = "00" + num;
        } else if (num < 1000) {
            workingNumber = "0" + num;
        } else {
            workingNumber = "" + num + "";
        }
        timestamp = [workingNumber.slice(0, 2), ":", workingNumber.slice(2)].join("");
        if (ampm === true) {
            timestamp = timestamp + "am";
        }
    } else {
        if (num > 1259 && ampm === true) {
            num = num - 1200;
        }
        if (num < 1000) {
            workingNumber = "0" + num + "";
        } else {
            workingNumber = "" + num + "";
        }
        timestamp = [workingNumber.slice(0, 2), ":", workingNumber.slice(2)].join("");
        if (ampm === true) {
            timestamp = timestamp + "pm";
        }
    }
    if (timestamp.substring(0, 1) === "0" && ampm === true) {
        timestamp = timestamp.substring(1);
    }
    return timestamp;
}

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

export const monthsCheckBox = [
    { label: "January", value: 0 },
    { label: "February", value: 1 },
    { label: "March", value: 2 },
    { label: "April", value: 3 },
    { label: "May", value: 4 },
    { label: "June", value: 5 },
    { label: "July", value: 6 },
    { label: "August", value: 7 },
    { label: "September", value: 8 },
    { label: "October", value: 9 },
    { label: "November", value: 10 },
    { label: "December", value: 11 }
];

export const shortMonths = ["Jan", "Febr", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const numberMonths = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

export const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const daysCheckBox = [
    { label: "Sunday", value: 0 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 }
];

export const shortDays = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];


export function getPaidStatusColour(status) {
    switch (status.toLowerCase()) {
        case "succeeded":
            return "green";
        case "paid":
            return "green";
        case "active":
            return "green";
        case "requires-action":
            return "red";
        case "refund-requested":
            return "red";
        case "refunded":
            return "orange";
        case "reserved":
            return "blue";
        case "error":
            return "red";
        case "limbo":
            return "purple";
        case "inactive":
            return "#8c8c8c";
        case "other":
            return "orange";
        default:
            return "#8c8c8c";
    }
}

export function getStatusString(status) {
    switch (status.toLowerCase()) {
        case "succeeded":
            return "Ready to go!";
        case "active":
            return "Ready to go!";
        case "requires-action":
            return "There has been a change to this ticket that needs to be addressed";
        case "refund-requested":
            return "Refund has been requested";
        case "refunded":
            return "Refund has been proceessed";
        case "reserved":
            return "Unpaid Reservation";
        case "error":
            return "There's been a problem";
        case "limbo":
            return "This ticket is in limbo";
        case "inactive":
            return "This ticket has been cancelled - customer not charged";
        default:
            return "Unknown Error";
    }
}

export function getPaidStatus(status) {
    // console.log(status);
    switch (status.toLowerCase()) {
        case "succeeded":
            return "Paid";
        case "active":
            return "Paid";
        case "archived":
            return "Archived";
        case "requires-action":
            return "Requires Action!";
        case "refund-requested":
            return "Refund Requested";
        case "refunded":
            return "Refunded";
        case "reserved":
            return "Reserved";
        case "error":
            return "Error";
        case "inactive":
            return "Inactive";
        case "limbo":
            return "Limbo";
        default:
            return "Unknown";
    }
}

// export function getPaidStatusColour(status) {
//     switch (status.toLowerCase()) {
//         case "succeeded":
//             return "green";
//         case "paid":
//             return "green";
//         case "active":
//             return "green";
//         case "requires-action":
//             return "red";
//         case "refund-requested":
//             return "red";
//         case "refunded":
//             return "orange";
//         case "reserved":
//             return "blue";
//         case "error":
//             return "red";
//         case "limbo":
//             return "purple";
//         case "inactive":
//             return "#8c8c8c";
//         case "other":
//             return "orange";
//         default:
//             return "#8c8c8c";
//     }
// }

// export function getStatusString(status) {
//     switch (status.toLowerCase()) {
//         case "succeeded":
//             return "Ready to go!";
//         case "active":
//             return "Ready to go!";
//         case "requires-action":
//             return "There has been a change to this ticket that needs to be addressed";
//         case "refund-requested":
//             return "Refund has been requested";
//         case "refunded":
//             return "Refund has been proceessed";
//         case "reserved":
//             return "Unpaid Reservation";
//         case "error":
//             return "There's been a problem";
//         case "limbo":
//             return "This ticket is in limbo";
//         case "inactive":
//             return "This ticket has been cancelled - customer not charged";
//         default:
//             return "Unknown Error";
//     }
// }

// export function getPaidStatus(status) {
//     // console.log(status);
//     switch (status.toLowerCase()) {
//         case "succeeded":
//             return "Paid";
//         case "active":
//             return "Paid";
//         case "archived":
//             return "Archived";
//         case "requires-action":
//             return "Requires Action!";
//         case "refund-requested":
//             return "Refund Requested";
//         case "refunded":
//             return "Refunded";
//         case "reserved":
//             return "Reserved";
//         case "error":
//             return "Error";
//         case "inactive":
//             return "Inactive";
//         default:
//             return "Unknown";
//     }
// }

// export function getPaidStatus(status) {
// 	// console.log(status);
// 	switch (status.toLowerCase()) {
// 		case "succeeded":
// 			return "Paid"
// 		case "active":
// 			return "Paid"
// 		case "archived":
// 			return "Archived"
// 		case "requires-action":
// 			return "Requires Action!"
// 		case "refund-requested":
// 			return "Refund Requested"
// 		case "refunded":
// 			return "Refunded"
// 		case "reserved":
// 			return "Reserved"
// 		case "error":
// 			return "Error"
// 		case "inactive":
// 			return "Inactive"
// 		default:
// 			return "Unknown"
// 	}
// }

export function getServerStatus(status) {
    switch (status) {
        case "Paid":
            return "active";
        case "Archived":
            return "archived";
        case "Requires Action!":
            return "requires-action";
        case "Refund Requested":
            return "refund-requested";
        case "Refunded":
            return "refunded";
        case "Reserved":
            return "reserved";
        case "Inactive":
            return "inactive";
        case "Limbo":
            return "limbo";
        default:
            return "Unknown";
    }
}

export function getFormattedPrice(value) {
    if (value === 0) {
        return "No Payment";
    } else {
        let valueString = value.toString();
        let cents = valueString.substring(valueString.length - 2);
        let euros = valueString.substring(0, valueString.length - 2);
        return "€ " + euros + "." + cents;
    }
}

export function getServerTicket(ticket) {
    // console.log(ticket.ticket);
    // console.log(ticket.payment);
    return {
        _id: ticket._id,
        userid: ticket.userid,
        name: ticket.name,
        email: ticket.email,
        ticket: ticket.ticket,
        price: ticket.price,
        promo: null,
        method: ticket.method,
        status: ticket.status,
        payment: ticket.payment,
        datecreated: ticket.datecreated,
        dateredeemed: ticket.dateredeemed,
        deleted: ticket.deleted,
        passengers: ticket.passengers,
        meta: ticket.meta
    };
}

export function getOneDayTimesArray(timesObj) {
    // console.log(timesObj);
    const combined = [...timesObj.master, ...timesObj.reroute];
    return combined;
}

export function getTodayTickets(tickets) {
    if (tickets == null) {
        return [];
    } else if (tickets.length === 0) {
        return [];
    } else {
        let todayTickets = [];
        let date = new Date();
        date.setHours(0);
        date.setMinutes(1);
        let beforeDate = date;
        date.setHours(23);
        date.setMinutes(59);
        let afterDate = date;
        tickets.forEach((i) => {
            // console.log(i);
            if (i.date > beforeDate && i.date < afterDate) {
                return todayTickets.push(i);
            }
        });
        // return todayTickets;
        return todayTickets;
    }
}

export function getTicketIssues(date, tickets) {
    const yesterday = subDays(date, 1);

    if (tickets == null) {
        return [];
    } else if (tickets.length === 0) {
        return [];
    } else {
        let issueTickets = [];
        tickets.forEach((i) => {
            // console.log(i);
            if (i.status === "requires-action" || i.status === "refund-requested") {
                return issueTickets.push(i);
            }
        });

        const futureTickets = issueTickets.filter((t) => {
            // console.log(isAfter(yesterday, new Date(t.ticketOutDate)))
            return !isAfter(yesterday, new Date(t.ticketOutDate)) && !isAfter(yesterday, new Date(t.ticketReturnDate));
        });

        // console.log(futureTickets)

        return futureTickets;
    }
}
